import { Box, Container, Grid, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import FlexBox from "components/common/FlexBox";
import TextValidator from "components/common/input/TextValidator";
import PrimaryButton from "components/common/PrimaryButton";
import { chain, isEmpty, trim } from "lodash";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import Stack from "@mui/material/Stack";
import dropDownOption from "assets/data/dropdown-options";

import defaultValue from "assets/data/default-value";
import AddClientDialogue from "./AddClientDialogue";

import AddressInput from "./project-address/input";
import AddressList from "./project-address/list";
import RemoveProjectDialog from "./RemoveProjectDialog";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  top: {
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    position: "relative",
    padding: theme.spacing(2),
  },
  bottomBox: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  form: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  column: {
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
      padding: theme.spacing(2),
    },
  },
}));
export default function ProjectDetails({ Project = {} }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = Project || {};
  project.StartDate = project?.StartDate
    ? new Date(moment(project.StartDate).format(defaultValue.dateTimeFormat))
    : null;
  project.EndDate = project?.EndDate
    ? new Date(moment(project.EndDate).format(defaultValue.dateTimeFormat))
    : null;
  project.PaymentDate = project?.PaymentDate
    ? new Date(moment(project.PaymentDate).format(defaultValue.dateTimeFormat))
    : null;
  const departments = dropDownOption.Departments;
  const projectStatus = dropDownOption.ProjectStatus;

  const {
    ProjectName = "",
    Title = "",
    Agency = "",
    Location = "",
    Landmark = "",
    ProjectNo = "",
    ProjectClient = null,
    isNew = false,
    Id = "",
    ProjectUsers = [],
    ProjectAddresses = [],
    Department = null,
    ProjectStatus = null,
    Note = ""
  } = project;
  const [form, setForm] = useState({
    ProjectName: ProjectName,
    Agency: Agency,
    Title: Title,
    Location: Location,
    Landmark: Landmark,
    ProjectClient: ProjectClient || null,
    ProjectNo: ProjectNo,
    ProjectAddresses: ProjectAddresses,
    Id: Id,
    ProjectUsers: ProjectUsers && Array.isArray(ProjectUsers) && ProjectUsers.map(k => k.User),
    Department: Department ? departments.find(k => k.Id === Department) : null,
    ProjectStatus: ProjectStatus ? projectStatus.find(k => k.Id === ProjectStatus) : null,
    Note: Note
  });
  const hasEmptyFields = chain({
    ...form,
  })
    .omit([
      "Id",
      "Note",
      "ProjectNo",
      "ProjectUsers",
      "ProjectClient",
      "Title",
      "Agency",
      "Location",
      "Landmark",
      "ProjectAddresses",
    ])
    .mapValues(trim)
    .some(isEmpty)
    .value();

  const [openRemoveProjectDialog, setopenRemoveProjectDialog] = React.useState(false);

  const onRemove = () => {
    setopenRemoveProjectDialog(true);
  };
  const onAdd = () => {
    let body = { ...form };
    body.PaymentDate = body?.PaymentDate
      ? moment(body.PaymentDate).format(defaultValue.saveDateTimeFormat)
      : "";
    body.ProjectType = body?.ProjectType?.Id ? body.ProjectType.Id : "";
    body.Department = body?.Department?.Id ? body.Department.Id : "";
    body.ProjectStatus = body?.ProjectStatus?.Id ? body.ProjectStatus.Id : "";
    dispatch(actions.addProject(body));
  };


  const onRemoveConfirm = () => {
    dispatch(actions.removeProject(form));
  };
  const closeDialog = () => {
    setopenRemoveProjectDialog(false);
  };

  const disabled = false;

  const updateField = (prop, value) => setForm({ ...form, [prop]: value });
  const handleChange = (prop) => (e) => {
    if (prop === "PaymentReceived") {
      updateField(prop, e.target.checked);
    } else {
      updateField(prop, e && e.target ? e.target.value : e);
    }
  };

  const handleBlur = (prop) => (e) => updateField(prop, e.target.value.trim());

  const submitDisabled = hasEmptyFields;

  const getOptions = () => {
    if (ProjectClient) {
      dispatch(actions.getClientUsers(ProjectClient));
    }
    dispatch(actions.getClients());
  };
  useEffect(getOptions, []);

  const { clients, clientUsers } = useSelector(prop("client"));

  const [clientUsersList, setClientUsersList] = useState([]);

  useEffect(() => {
    setClientUsersList(clientUsers?.map(k => k.User) || [])
  }, [clientUsers])

  useEffect(() => {
    if (form.ProjectClient) {
      dispatch(actions.getClientUsers(form.ProjectClient));
    } else {
      setForm({ ...form, ProjectUsers: [] })
    }
  }, [form.ProjectClient])


  const createTask = (task, errorMsg) => {
    const projectAddress = form.ProjectAddresses;
    projectAddress.push({ Location: task });
    setForm({ ...form, ProjectAddresses: projectAddress });
  };

  const deleteTask = (taskToDelete) => {
    const projectAddress = form.ProjectAddresses.filter(
      (task) => task.Location !== taskToDelete
    );
    setForm({ ...form, ProjectAddresses: projectAddress });
  };

  const saveTask = (oldTask, newTask) => {
    let body = { ...form };
    console.log("saving task: " + oldTask + " => " + newTask);

    const projectAddress = body.ProjectAddresses;
    const foundTask = projectAddress.find((task) => task.Location === oldTask);
    // body.Department = body?.Department?.Id ? body.Department.Id : "";
    // body.ProjectStatus = body?.ProjectStatus?.Id ? body.ProjectStatus.Id : "";

    foundTask.Location = newTask;
    setForm({ ...body, ProjectAddresses: projectAddress });
  };
  const onAddButtonHandler = (type) => {
    setDialogueType(type);
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const [dialogueType, setDialogueType] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Container className={classes.root}>
        <FlexBox className={classes.top}>
          <Box flexGrow={1} pl={2}>
            <Typography variant="h5">
              <strong data-testid="label-user-administration">
                {!isNew ? <>Edit Project</> : <>Add a project</>}
              </strong>
            </Typography>
          </Box>
        </FlexBox>

        <Container className={classes.container}>
          <ValidatorForm instantValidate={true} autoComplete="off">
            <Grid container spacing={2} className={classes.form}>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="ProjectNo"
                  label="Project No"
                  value={form?.ProjectNo}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("ProjectNo")}
                  onBlur={handleBlur("ProjectNo")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="ProjectName"
                  label="Project Name"
                  value={form?.ProjectName}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("ProjectName")}
                  onBlur={handleBlur("ProjectName")}
                  validators={["required"]}
                  errorMessages={["this field is required"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="Agency"
                  label="Agency"
                  value={form?.Agency}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Agency")}
                  onBlur={handleBlur("Agency")}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="Title"
                  label="Title"
                  value={form?.Title}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Title")}
                  onBlur={handleBlur("Title")}
                />
              </Grid> */}


              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="Location"
                  label="Location"
                  value={form?.Location}
                  margin="normal"
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Location")}
                  onBlur={handleBlur("Location")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="department"
                  options={departments}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Department"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.Department}
                  onChange={(_event, newValue) => {
                    updateField("Department", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="projectStatus"
                  options={projectStatus}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Status"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.ProjectStatus}
                  onChange={(_event, newValue) => {
                    updateField("ProjectStatus", newValue);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Autocomplete
                    id="client-type-select"
                    options={clients}
                    getOptionLabel={(option) => option.Name}
                    value={form?.ProjectClient}
                    onChange={(_event, newValue) => {
                      updateField("ProjectClient", newValue);
                    }}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client"
                        variant="standard"
                        margin="normal"
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {/* <AddCircleOutlinedIcon
                    color="primary"
                    fontSize="large"
                    onClick={() => onAddButtonHandler("Client")}
                  /> */}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <TextValidator
                  autoComplete="new-password"
                  name="Note"
                  label="Project Note"
                  value={form?.Note}
                  margin="normal"
                  multiline
                  // rows={2}
                  fullWidth
                  disabled={disabled}
                  data-testid="input-user-name"
                  onChange={handleChange("Note")}
                  onBlur={handleBlur("Note")}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.column}>
                <Autocomplete
                  id="user-type-select"
                  options={clientUsersList}
                  multiple={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Users"
                      variant="standard"
                      margin="normal"
                      fullWidth
                    />
                  )}
                  getOptionLabel={(option) => option.Name}
                  value={form?.ProjectUsers}
                  onChange={(_event, newValue) => {
                    updateField("ProjectUsers", newValue);
                  }}
                />
              </Grid>

            </Grid>
          </ValidatorForm>

          <Box style={{ marginTop: "15px" }}>
            <AddressInput
              createTask={createTask}
              taskList={form.ProjectAddresses}
            />
            <AddressList
              taskList={form.ProjectAddresses}
              deleteTask={deleteTask}
              saveTask={saveTask}
            // toggleTask={toggleTask}
            />
          </Box> 
        </Container>

        <FlexBox justifyContent="center">
          {!isNew ?
            <PrimaryButton
              onClick={onRemove}
              disabled={submitDisabled}
              data-testid="btn-remove-task"
              color="error"
            >
              Remove
            </PrimaryButton>
            : ''}
        </FlexBox>
        <FlexBox justifyContent="center">
          {!isNew ? (
            <>
              <PrimaryButton
                onClick={onAdd}
                data-testid="btn-remove-user"
                disabled={submitDisabled}
              >
                Save
              </PrimaryButton>
            </>
          ) : (
            <PrimaryButton
              onClick={onAdd}
              data-testid="btn-add-user"
              disabled={submitDisabled}
            >
              Add Project
            </PrimaryButton>
          )}
        </FlexBox>
        <AddClientDialogue
          handleClose={handleClose}
          open={open}
          type={dialogueType}
        ></AddClientDialogue>

        <RemoveProjectDialog
          open={openRemoveProjectDialog}
          onClose={closeDialog}
          onRemoveConfirm={onRemoveConfirm}
        ></RemoveProjectDialog>
      </Container>
    </>
  );
}
