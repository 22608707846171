import moment from "moment";
import {
  all, put, takeLatest
} from "redux-saga/effects";

import path from "routesPath";
import {
  endProgress,
  failProgress,
  startProgress
} from "sagas/common";
import Api from "services/api";
import * as actions from "../actions";
import history from "../history";

function* getCreedRequests(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedRequests = yield Api.creedRequest.getCreedRequestsUsingGET(body.requestType, body.searchString)

    yield put(actions.setCreedRequestProps({
      creedRequests
    }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addCreedRequest(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedRequest = yield Api.creedRequest.addCreedRequest(body);
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* addUserToCreedRequest(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedRequest = yield Api.creedRequest.addUserToCreedRequest(body);
    if (body.goBack) {
      // history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* approveCreedRequest(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedResp = yield Api.creedRequest.approveCreedRequest(body.Id);
    creedResp["StartTime"] = moment(creedResp.StartDate).format('HH:mm');
    creedResp["EndTime"] = moment(creedResp.EndDate).format('HH:mm');
    yield put(actions.setCreedRequestProps({ creedRequestDetails: creedResp }));
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* reassignCreedRequest(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedResp = yield Api.creedRequest.reassignCreedRequest(body.Id);
    creedResp["StartTime"] = moment(creedResp.StartDate).format('HH:mm');
    creedResp["EndTime"] = moment(creedResp.EndDate).format('HH:mm');
    yield put(actions.setCreedRequestProps({ creedRequestDetails: creedResp }));
    if (body.goBack) {
      history.goBack()
    }
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
function* siteContactSuggestionByProject(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedResp = yield Api.creedRequest.siteContactSuggestionByProjectRequest(body.Id);
    yield put(actions.setCreedRequestProps({ siteContactSuggestionByProject: creedResp }));

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}


function* removeCreedRequest(action) {
  try {
    const id = action.payload.creedRequest.Id;
    const creedRequest = yield Api.creedRequest.removeCreedRequest(id);

    if (action.payload?.requestType) {
      yield getCreedRequests(action)
    }
    if (action.payload.goBack) {
      history.goBack()
    }
  } catch (error) {
    console.error(error);
  }
}

function* cancelCreedRequest(action) {
  try {
    const id = action.payload.creedRequest.Id;
    const creedRequest = yield Api.creedRequest.cancelCreedRequest(id);

    if (action.payload?.requestType) {
      yield getCreedRequests(action)
    }
    if (action.payload.goBack) {
      history.goBack()
    }
  } catch (error) {
    console.error(error);
  }
}

function* refreshCreedRequest() {
  const progress = yield startProgress();
  yield getCreedRequests();
  yield endProgress(progress);
}

function* getCreedRecommendation(action) {
  const { creedRequest } = action.payload;
  const creedResp = yield Api.creedRequest.getCreedRecommendationByIdUsingGET(creedRequest.Id);
  yield put(actions.setCreedRequestProps({ creedRecommendation: creedResp }));

}
function* showCreedRequestDetails(action) {
  const { creedRequest } = action.payload;
  if (creedRequest.isNew) {
    let selectedDayDate = new Date();
    selectedDayDate.setDate(selectedDayDate.getDate() + 1);
    const startTime = moment(moment(selectedDayDate).format("YYYY-MM-DD"))
      .set("hour", "07")
      .set("minute", "00");
    const endTime = moment(moment(selectedDayDate).format("YYYY-MM-DD"))
      .set("hour", "15")
      .set("minute", "30");
    yield put(actions.setCreedRequestProps({
      creedRequestDetails: {
        ...creedRequest,
        StartDate: startTime.toISOString(),
        EndDate: endTime.toISOString(),
        StartTime: startTime.format('HH:mm'),
        EndTime: endTime.format('HH:mm'),
      }
    }));
    history.push(path.creedRequest.creedRequestDetail);
  }
  else {
    const creedResp = yield Api.creedRequest.getCreedRequestByIdUsingGET(creedRequest.Id);
    creedResp["StartTime"] = moment(creedResp.StartDate).format('HH:mm');
    creedResp["EndTime"] = moment(creedResp.EndDate).format('HH:mm');
    yield put(actions.setCreedRequestProps({ creedRequestDetails: creedResp }));
    history.push(path.creedRequest.creedRequestDetail);
  }
}
function* getjobDetailsByNotifyId(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const jobDetails = yield Api.creedRequest.getJobDetailsByNotifyIdUsingGET(body.Id);
    yield put(actions.setCreedRequestProps({ jobDetails: jobDetails }));
    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}

function* getCreedFeedBacks(action) {
  const progress = yield startProgress();
  try {
    const body = action.payload;
    const creedResp = yield Api.creedRequest.getCreedFeedBacks(body.Id);
    yield put(actions.setCreedRequestProps({ feedbackDataSet: creedResp }));

    yield endProgress(progress);
  } catch (error) {
    yield failProgress(progress);
    yield put(actions.showErrorDrawer(error, error.error));
  }
}
export default function* saga() {
  yield all([
    takeLatest(actions.GET_CREED_REQUESTS, getCreedRequests),
    takeLatest(actions.ADD_CREED_REQUEST, addCreedRequest),
    takeLatest(actions.ADD_USER_TO_CREED_REQUEST, addUserToCreedRequest),
    takeLatest(actions.APPROVE_CREED_REQUEST, approveCreedRequest),
    takeLatest(actions.REASSIGN_CREED_REQUEST, reassignCreedRequest),
    takeLatest(actions.SITE_CONTACT_SUGGESTION_BYPROJECT, siteContactSuggestionByProject),
    takeLatest(actions.CREED_FEEDBACKS, getCreedFeedBacks),
    takeLatest(actions.REMOVE_CREED_REQUEST, removeCreedRequest),
    takeLatest(actions.CANCEL_CREED_REQUEST, cancelCreedRequest),
    takeLatest(actions.REFRESH_CREED_REQUESTS, refreshCreedRequest),
    takeLatest(actions.CREED_REQUEST_DETAILS, showCreedRequestDetails),
    takeLatest(actions.GET_JOBDETAILS_BYNOTIFY_ID, getjobDetailsByNotifyId),
    takeLatest(actions.CREED_RECOMMENDATION, getCreedRecommendation),
  ]);
}
