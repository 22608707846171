/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import User from './User';
/**
 * The CreedRequest model module.
 * @module model/CreedRequest
 * @version 1.0
 */
class CreedRequest {
    /**
     * Constructs a new <code>CreedRequest</code>.
     * @alias module:model/CreedRequest
     */
    constructor() {

        CreedRequest.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>CreedRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CreedRequest} obj Optional instance to populate.
     * @return {module:model/CreedRequest} The populated <code>CreedRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreedRequest();

            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }
            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }
            if (data.hasOwnProperty('QB')) {
                obj['QB'] = ApiClient.convertToType(data['QB'], 'Boolean');
            }

            if (data.hasOwnProperty('Creed') && !!data.Creed) {
                obj['Creed'] = ApiClient.convertToType(data['Creed'], 'Object');
            }
            if (data.hasOwnProperty('Project') && !!data.Project) {
                obj['Project'] = ApiClient.convertToType(data['Project'], 'Object');
            }
            if (data.hasOwnProperty('RequestBy') && !!data.RequestBy) {
                obj['RequestBy'] = ApiClient.convertToType(data['RequestBy'], 'Object');
            }
            if (data.hasOwnProperty('FillInFor') && !!data.FillInFor) {
                obj['FillInFor'] = ApiClient.convertToType(data['FillInFor'], User);
            }

            if (data.hasOwnProperty('Comment')) {
                obj['Comment'] = ApiClient.convertToType(data['Comment'], 'String');
            }
            if (data.hasOwnProperty('Location')) {
                obj['Location'] = ApiClient.convertToType(data['Location'], 'String');
            }
            if (data.hasOwnProperty('WorkScope')) {
                obj['WorkScope'] = ApiClient.convertToType(data['WorkScope'], 'String');
            }
            if (data.hasOwnProperty('ContactName')) {
                obj['ContactName'] = ApiClient.convertToType(data['ContactName'], 'String');
            }
            if (data.hasOwnProperty('ContactEmail')) {
                obj['ContactEmail'] = ApiClient.convertToType(data['ContactEmail'], 'String');
            }
            if (data.hasOwnProperty('ContactPhoneNumber')) {
                obj['ContactPhoneNumber'] = ApiClient.convertToType(data['ContactPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('Status')) {
                obj['Status'] = ApiClient.convertToType(data['Status'], 'String');
            }
            if (data.hasOwnProperty('UserCreedsNotify')) {
                obj['UserCreedsNotify'] = ApiClient.convertToType(data['UserCreedsNotify'], 'Array');
            }
            if (data.hasOwnProperty('AuditLogs')) {
                obj['AuditLogs'] = ApiClient.convertToType(data['AuditLogs'], 'Array');
            }
        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} CreedRequestName
 */
CreedRequest.prototype['CreedRequestName'] = undefined;

CreedRequest.prototype['Id'] = undefined;
/**
 * @member {Object} CreedRequestClients
 */



export default CreedRequest;

