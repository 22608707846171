import Paper from '@mui/material/Paper';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import * as actions from "actions";
import React, { useEffect, useState } from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Checkbox, Chip, FormControl, InputLabel, Link, MenuItem, Select, Stack } from '@mui/material';
import dropDownOption from "assets/data/dropdown-options";
import FlexBox from 'components/common/FlexBox';
import SearchBar from 'components/common/SearchBar';
import { prop } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'services/storage';
import ExportToExcel from '../common/Export';
import EditAddressDialogue from './editAddressDialogue';
import EditNoteDialog from './editNoteDialogue';
import EditRosterDialogue from './editRosterDialogue';
const colorMap = {
    "SM": "#70AD47",
    "CCM SM": "#375623",
    "SC": "#E2EFDA",
    "CSS": "#E2EFDA",
    "CSE": "#E2EFDA",
    "Pending": "#DDEBF7",
    "Rejected": "#FFD6D6",
    "Removed": "#000000"

}
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
    },
    top: {
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        position: "relative",
        padding: theme.spacing(2),
    },
    bottomBox: {
        width: "100%",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    card: {
        marginBottom: 5,
        border: 'none',
        boxShadow: 'none'
    },
    cardContent: {
        padding: "10px 5px!important",
    },
    tableHeight: {
        // maxHeight: 'calc(100vh - 233px)'
        maxHeight: "calc(100vh - 266px)",
    },
    sticky: {
        position: "sticky",
        left: 0,

        // backgroundColor: '#e0e0e0',
        "&:hover $addCircle": {
            display: 'inline-block',
        }
        // maxWidth:'130px'
        // boxShadow: "5px 2px 5px grey",
        // borderRight: "2px solid black"
    },
    disable: {
        opacity: 0.5,
        pointerEvents: 'none'
    },
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderLeft: "1px solid rgba(224, 224, 224, 1)"
        }
    },
    addCircle: {
        display: 'none',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: '0px 0px 13px 3px #939090',
        borderRadius: '50%',
        padding: '3px'

    },
    column: {
        // minWidth: '200px',
        // width: '200px',
        // maxWidth: '250px',
        padding: '0px 2px',
        position: 'relative',
        "&:hover $addCircle": {
            display: 'inline-block',
        }
    },
}));
function DynamicRosterTable({ data,
    columns,
    showCheckColumn,
    alowEdit,
    selectedRows,
    disabledIds = [],
    setSelectedRows,
    handleCheckboxChange,
    allowDepartmentChange = true,
    tableHeight = 'calc(100vh - 266px)'
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const { LoginUserRole } = useSelector(prop("user"));
    const [searchText, setSearchText] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const { selectedDepartmentValue } = useSelector(prop('roster'));

    const [openAddCreedDialogue, setOpenAddCreedDialogue] = React.useState(false);
    const [openAddNoteDialogue, setOpenAddNoteDialogue] = React.useState(false);
    const [openAddAddressDialogue, setOpenAddAddressDialogue] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState({});

    const [category, setCategory] = useState();
    const departments = dropDownOption.Departments;
    const handleSort = (columnId) => {
        if (orderBy === columnId && order === 'asc') {
            setOrder('desc');
        } else {
            setOrderBy(columnId);
            setOrder('asc');
        }
    };
    const closeAddCreedDialog = () => {
        setOpenAddCreedDialogue(false);
    }

    const closeAddNoteDialog = () => {
        setOpenAddNoteDialogue(false);
    }
    const closeAddAddressDialog = () => {
        setOpenAddAddressDialogue(false);
    }
    const dispatch = useDispatch();

    const onUpdate = (data) => {
        let tempData = {
            Id: data.Id,
            Date: data.Date,
            User: {
                Id: selectedItem.RowData.UserId,
            },
            Project: {
                Id: selectedItem.ProjectId
            },
            Creed: data.Creed
        }
        dispatch(actions.addMTA(tempData));
        setOpenAddCreedDialogue(false);

    };

    const onNoteUpdate = (data) => {
        let tempData = {
            User: {
                Id: selectedItem.UserId,
            },
            Data: {
                Note: data.Note
            }
        }
        dispatch(actions.addNote(tempData));
        setOpenAddNoteDialogue(false);

    };
    const onAddressUpdate = (data) => {
        let tempData = {
            User: {
                Id: selectedItem.UserId,
            },
            Data: {
                Address: data.Address
            }
        }
        dispatch(actions.addNote(tempData));
        setOpenAddAddressDialogue(false);

    };

    const onFieldUpdate = (row, key, value) => {
        let tempData = {
            User: {
                Id: row.UserId,
            },
            Data: {
                [key]: value
            }
        }
        dispatch(actions.updateField(tempData));

    };

    const onUserCreedUpdate = (row, key, value) => {
        // alert(JSON.stringify(row))
        let tempData = {
            User: {
                Id: row.UserId,
            },
            Creed: {
                Id: key
            },
            Value: value
        }
        dispatch(actions.updateUserCreed(tempData));

    };
    const updateTask = () => {
        let filteredList = data ? [...data] : [];
        if (searchText.length > 0) {
            filteredList = filteredList.filter(k => k.Name?.toLowerCase().includes(searchText)
                || k.User?.Email?.toLowerCase().includes(searchText)
                || k.User?.PhoneNo?.toLowerCase().includes(searchText)
            );
        }
        filteredList.sort((a, b) => {
            if (order === 'asc') {
                return a[orderBy] < b[orderBy] ? -1 : 1;
            } else {
                return a[orderBy] > b[orderBy] ? -1 : 1;
            }
        });
        setSortedData(filteredList);
    };
    useEffect(() => {
        const selectedCategory = selectedDepartmentValue ? selectedDepartmentValue : departments[0].Id;
        setCategory(selectedCategory)
    }, [])
    useEffect(updateTask, [orderBy, order, searchText, data]);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const classes = useStyles();
    const onSearch = (e) => {
        setSearchText(e.target.value.trim().toLowerCase());
    }
    const onAddButtonHandler = (RowData) => {
        setSelectedItem({ ...RowData });
        setOpenAddCreedDialogue(true)
    }
    const onAddButtonNoteHandler = (RowData, columnName) => {
        setSelectedItem({ ...RowData });
        if (columnName == 'Note') {
            setOpenAddNoteDialogue(true)
        }
        if (columnName == 'Address') {
            setOpenAddAddressDialogue(true)
        }
    }
    const onEditUserHandler = (RowData) => {
        let user = { Id: RowData.UserId }
        dispatch(actions.showUserDetails(user));
    }

    const handleDepartmentChange = (event) => {
        dispatch(
            actions.setRosterProps({ selectedDepartmentValue: event.target.value })
        );
        storage.set('selectedRosterDepartmentValue')(event.target.value);
        setCategory(event.target.value);
    };
    return (
        <Paper>
            <Box sx={{ p: 1 }}>
                {
                    allowDepartmentChange &&
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <>
                            <InputLabel id="demo-simple-select-standard-label">
                                Department
                            </InputLabel>
                            <Select
                                defaultValue={departments[0].Id}
                                labelId="department-select-standard-label"
                                id="department-select-standard"
                                value={category || ""}
                                onChange={handleDepartmentChange}
                                label="Department(s)"
                            >
                                {departments?.map((department) => (
                                    <MenuItem value={department.Id}>{department.Name}</MenuItem>
                                ))}

                            </Select>
                        </>
                    </FormControl>
                }
                <FlexBox justifyContent="space-between">
                    <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
                    <ExportToExcel columns={columns} data={sortedData}></ExportToExcel>
                </FlexBox>

            </Box>
            <TableContainer sx={{ maxHeight: tableHeight }}>
                <Table stickyHeader aria-label="sticky table" className={classes.table} size='small'>
                    <TableHead>
                        <TableRow>
                            {
                                showCheckColumn && <TableCell align="center" style={{ maxWidth: '250px' }}>
                                    <Typography>
                                        Select
                                    </Typography>
                                </TableCell>
                            }
                            {columns.map((column) => (
                                <TableCell key={column.id} align="center" style={{ maxWidth: '250px', minWidth: ['Phone', 'Name', 'Note'].includes(column.id) ? '150px' : 'auto', zIndex: column.id == 'Name' ? 4 : 3 }}
                                    className={column.id == 'Name' ? classes.sticky : ""}>
                                    {
                                        column.sortable ?

                                            <TableSortLabel
                                                active={orderBy === column.id}
                                                direction={orderBy === column.id ? order : 'asc'}
                                                onClick={() => handleSort(column.id)}
                                                style={{ cursor: 'pointer' }}

                                            >
                                                {column.label}
                                            </TableSortLabel> :
                                            column.label
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => ( */}
                        {sortedData.map((row, index) => (
                            <TableRow key={index}>

                                {
                                    showCheckColumn &&
                                    <TableCell align="center">
                                        <Checkbox
                                            checked={selectedRows.includes(row.UserId)}
                                                disabled={disabledIds.includes(row.UserId)}
                                            onChange={() => handleCheckboxChange(row.UserId)}
                                        />
                                    </TableCell>
                                }
                                {columns.map((column) => (
                                    <TableCell key={column.id} align="center" className={column.id == 'Name' ? classes.sticky : classes.column}
                                        style={{ maxWidth: '250px', zIndex: column.id == 'Name' ? 2 : 1, background: 'white', opacity: row.IsActive ? 1 : 0.5 }} >

                                        <Typography
                                            className={classes.separator}
                                            // onClick={() => onViewTask(cellData, "Monday", row.User)}
                                            variant="caption"
                                            component="div"
                                        >
                                            {(() => {
                                                switch (column.id) {
                                                    case 'Name':
                                                        return <>
                                                            <>{row[column.id]}</>
                                                            <br />
                                                            {alowEdit && LoginUserRole === "admin" ? (
                                                                <>
                                                                    <EditOutlinedIcon
                                                                        className={classes.addCircle}
                                                                        color="primary"
                                                                        fontSize="large"
                                                                        onClick={() => onEditUserHandler(row)}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>;

                                                    case 'Recommended':
                                                        return <>
                                                            <Chip label={row[column.id]} size="small"
                                                                color={row[column.id] === 'Yes' ? "success" : "error"} /></>;
                                                    case 'SafetyPersonal':
                                                        return <>
                                                            {row[column.id]?.map((item) => (
                                                                <Chip
                                                                    key={item.Id}
                                                                    size='small'
                                                                    label={item.Creed.Value}
                                                                    spacing={1}
                                                                    style={{
                                                                        backgroundColor: item.Creed.BackgroundColor,
                                                                        color: item.Creed.TextColor,
                                                                        margin: '2px',
                                                                    }}
                                                                />
                                                            ))}</>;
                                                    case 'Note':
                                                    case 'Address':
                                                        return <>
                                                            <>{row[column.id]}</>
                                                            <br />
                                                            {alowEdit && LoginUserRole === "admin" ? (
                                                                <>
                                                                    <EditOutlinedIcon
                                                                        className={classes.addCircle}
                                                                        color="primary"
                                                                        fontSize="large"
                                                                        onClick={() => onAddButtonNoteHandler(row, column.id)}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>;
                                                    case 'WeekDayTimeSlots':
                                                    case 'WeekEndTimeSlots':
                                                    case 'Availability':
                                                        return <>
                                                            {row[column.id]?.map((item) => (
                                                                <Chip
                                                                    key={item}
                                                                    label={item}
                                                                    size='small'
                                                                    spacing={1}
                                                                    style={{
                                                                        backgroundColor: item.BackgroundColor,
                                                                        color: item.TextColor,
                                                                        margin: '2px',
                                                                    }}
                                                                />
                                                            ))}
                                                            <br />
                                                            {alowEdit && LoginUserRole === "admin" ? (
                                                                <>
                                                                    <EditOutlinedIcon
                                                                        className={classes.addCircle}
                                                                        color="primary"
                                                                        fontSize="large"
                                                                        onClick={() => onAddButtonHandler(row)}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>;
                                                    case 'Mon':
                                                    case 'Tue':
                                                    case 'Wed':
                                                    case 'Thu':
                                                    case 'Fri':
                                                    case 'Sat':
                                                    case 'Sun':
                                                        return <>
                                                            <Stack direction="column" spacing={1}>
                                                                {row[column.id]?.map((item) => (
                                                                    <Chip
                                                                        key={item.Label}
                                                                        label={item.Label}
                                                                        size='small'
                                                                        style={{
                                                                            backgroundColor: item?.BackgroundColor,
                                                                            color: item?.TextColor,
                                                                            margin: '2px',
                                                                        }}
                                                                    />
                                                                ))}
                                                            </Stack>
                                                            <br />
                                                            {alowEdit && LoginUserRole === "admin" ? (
                                                                <>
                                                                    <EditOutlinedIcon
                                                                        className={classes.addCircle}
                                                                        color="primary"
                                                                        fontSize="large"
                                                                        onClick={() => onAddButtonHandler(row)}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>;
                                                    case 'NINTYDROWNYCT':
                                                    case 'NINTYDROWMNR':
                                                    case 'NINTYDROWLIRR':
                                                    case 'NINTYDROWAMTRACK':
                                                    case 'NINTYDROWCSX':
                                                    case 'TWOYSAFETYCOORDINATOR':
                                                    case 'FIVEYSAFETYCOORDINATOR':
                                                        return <>
                                                            <Checkbox
                                                                checked={row[column.id]}
                                                                onChange={(event) => onFieldUpdate(row, column.id, event.target.checked)}
                                                            /></>;
                                                    default:
                                                        return <>{column.type == 'creed' ? (row[column.id]?.Expiration || row[column.id]?.Link || row[column.id]?.LicenseNo) ?
                                                            <>
                                                                {
                                                                    row[column.id]?.Expiration ? <Chip
                                                                        key={row[column.id]?.Expiration}
                                                                        label={row[column.id]?.Expiration}
                                                                        size='small'
                                                                        style={{
                                                                            backgroundColor: row[column.id]?.BackgroundColor,
                                                                            color: row[column.id]?.TextColor,
                                                                            margin: '2px',
                                                                        }}
                                                                    /> : ''
                                                                }

                                                                {
                                                                    row[column.id]?.LicenseNo ? <Chip
                                                                        key={row[column.id]?.LicenseNo}
                                                                        label={row[column.id]?.LicenseNo}
                                                                        size='small'
                                                                        style={{
                                                                            backgroundColor: row[column.id]?.BackgroundColor,
                                                                            color: row[column.id]?.TextColor,
                                                                            margin: '2px',
                                                                        }}
                                                                    /> : ''
                                                                }
                                                                {
                                                                    row[column.id]?.Link ? <Link href={row[column.id]?.Link} target="_blank" rel="noopener noreferrer">
                                                                        Click Here
                                                                    </Link> : ''
                                                                }
                                                            </>
                                                            : <>

                                                                {
                                                                    column.creedFieldType == 'YesNo' ? <Select
                                                                        value={String(row[column.id]?.HasCertification)} // Convert boolean to string
                                                                        onChange={(event) => {
                                                                            const value = event.target.value === 'true'; // Convert string to boolean
                                                                            onUserCreedUpdate(row, column.id, value);
                                                                        }}
                                                                    >
                                                                        <MenuItem value="true">Y</MenuItem>
                                                                        <MenuItem value="false">N</MenuItem>
                                                                    </Select> : ''
                                                                }
                                                            </> : row[column.id]}</>;
                                                }
                                            })()}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={columns.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditRosterDialogue
                open={openAddCreedDialogue}
                handleClose={closeAddCreedDialog}
                onUpdate={onUpdate}
                data={selectedItem}
            >
            </EditRosterDialogue>
            <EditNoteDialog
                open={openAddNoteDialogue}
                handleClose={closeAddNoteDialog}
                onUpdate={onNoteUpdate}
                data={selectedItem}
            >
            </EditNoteDialog>
            <EditAddressDialogue
                open={openAddAddressDialogue}
                handleClose={closeAddAddressDialog}
                onUpdate={onAddressUpdate}
                data={selectedItem}
            >
            </EditAddressDialogue>
        </Paper>
    );
}

export default DynamicRosterTable;
