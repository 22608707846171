import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Container, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import * as actions from "actions";
import ExportToExcel from 'components/common/Export';
import FlexBox from 'components/common/FlexBox';
import SearchBar from "components/common/SearchBar";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmailStr, getNameStr } from 'utils/strUtils';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
  },
  ul: {
    padding: 0,
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    border: "1px solid rgb(216,216,216)",
    padding: "10px",
    borderRadius: "6px",
    marginBottom: "16px",
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(2),
  },
}));

export default function ClientList({ height, maxHeight, onItemSelect, onAddNewClient }) {
  const classes = useStyles();
  const handleClick = (client) => !!onItemSelect && onItemSelect(client);
  const handelNewClientClick = () => !!onAddNewClient && onAddNewClient();


  const dispatch = useDispatch();
  const updateAll = () => {
    dispatch(actions.getClients());
    dispatch(actions.getUsers());
  }

  useEffect(updateAll, []);
  const { clients } = useSelector(prop('client'));
  const [filteredList, setFilteredList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const onSearch = (e) => {
    setSearchText(e.target.value.trim().toLowerCase());
  }

  useEffect(() => {
    let filterdList = clients ? clients : [];
    if (searchText.length > 0) {
      filterdList = filterdList.filter(k => k.Name?.toLowerCase().includes(searchText));
    }
    setFilteredList(filterdList)
  }, [clients, searchText]);
  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          <strong data-testid="label-user-administration">
            Client's
          </strong>
        </Typography>
        {/* <Typography
          color="textSecondary"
          data-testid="label-user-administration-secondary-text"
        >
          Add as many Client as you want.
        </Typography> */}
      </Box>
      <Box mt={1} mb={1}>
        {/* <Typography variant="h6" gutterBottom>
          <strong data-testid="label-user-administration-list-user">
            A list of Client
          </strong>
        </Typography> */}
        <FlexBox justifyContent="space-between">
          <SearchBar placeHolder='Type to Search' onChange={onSearch}></SearchBar>
          <ExportToExcel columns={[
            { id: 'Name', label: 'Name' },
            { id: 'ClientUsers', label: 'Client User(s)' },
          ]} data={filteredList}>

          </ExportToExcel>
        </FlexBox>

      </Box>
      {/* <List className={classes.root} style={{ height }} dense={false}>
        <ul className={classes.ul}>
          <ListItem
            dense
            disableGutters
            onClick={onAddNewClient}
            className={classes.item}
            data-testid={'li-add-user'}
          >
            <ListItemAvatar>
              <AddIcon />
            </ListItemAvatar>
            <ListItemText
              primary={'Add a Client'}
              primaryTypographyProps={{ className: "bold" }}
            />
          </ListItem>
          {filteredList?.map((client) => (
            <ClientListItem
              key={client.Id}
              client={client}
              onClick={handleClick(client)}
              itemClasses={classes.item}

            />
          ))}


        </ul>
      </List> */}

      <TableContainer component={Paper} style={{ maxHeight }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Client Users</TableCell>

              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredList?.map((client, index) => (

              <TableRow
              >
                <TableCell>{client.Name} </TableCell>
                <TableCell>{client.ClientUsers?.map(k => `${getNameStr(k.User)} <${getEmailStr(k.User)}>`)?.join(',  ')}</TableCell>
                {/* <TableCell>{project.ProjectUsers && Array.isArray(project.ProjectUsers) ? project.ProjectUsers.map(k => k.User.FirstName).join(",") : ""}</TableCell> */}
                <TableCell>
                  <Button onClick={() => {
                    //alert(JSON.stringify(project));
                    handleClick(client)
                  }} size="small" variant="contained">View</Button>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Fab color="primary" className={classes.fab} onClick={handelNewClientClick} data-testid={"floating-add-button"}>
        <AddIcon />
      </Fab>
    </Container>
  );
}
