/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import { getNameStr } from 'utils/strUtils';
import ApiClient from '../ApiClient';
import ProjectUser from './ProjectUsers';
/**
 * The Project model module.
 * @module model/Project
 * @version 1.0
 */
class Project {
    /**
     * Constructs a new <code>Project</code>.
     * @alias module:model/Project
     */
    constructor() {

        Project.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) {
    }

    /**
     * Constructs a <code>Project</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Project} obj Optional instance to populate.
     * @return {module:model/Project} The populated <code>Project</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Project();

            if (data.hasOwnProperty('ProjectName')) {
                obj['ProjectName'] = ApiClient.convertToType(data['ProjectName'], 'String');
            }   
            if (data.hasOwnProperty('Agency')) {
                obj['Agency'] = ApiClient.convertToType(data['Agency'], 'String');
            }

            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'Date');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'Date');
            }
            if (data.hasOwnProperty('Location')) {
                obj['Location'] = ApiClient.convertToType(data['Location'], 'String');
            }
            if (data.hasOwnProperty('ProjectType')) {
                obj['ProjectType'] = ApiClient.convertToType(data['ProjectType'], 'String');
            }
            if (data.hasOwnProperty('Note')) {
                obj['Note'] = ApiClient.convertToType(data['Note'], 'String');
            }
            if (data.hasOwnProperty('Contractor') && !!data.Contractor) {
                obj['Contractor'] = ApiClient.convertToType(data['Contractor'], 'Object');
                obj['Contractor']['Name'] = getNameStr(data['Contractor']);
            }
            if (data.hasOwnProperty('SubContractor') && !!data.SubContractor) {
                obj['SubContractor'] = ApiClient.convertToType(data['SubContractor'], 'Object');
                obj['SubContractor']['Name'] = getNameStr(data['SubContractor']);
            }
            if (data.hasOwnProperty('PaymentReceived')) {
                obj['PaymentReceived'] = ApiClient.convertToType(data['PaymentReceived'], 'Boolean');
            }
            if (data.hasOwnProperty('PaymentDate')) {
                obj['PaymentDate'] = ApiClient.convertToType(data['PaymentDate'], 'Date');
            }
            if (data.hasOwnProperty('ProjectNo')) {
                obj['ProjectNo'] = ApiClient.convertToType(data['ProjectNo'], 'String');
            }
            if (data.hasOwnProperty('Landmark')) {
                obj['Landmark'] = ApiClient.convertToType(data['Landmark'], 'String');
            }
            if (data.hasOwnProperty('Department')) {
                obj['Department'] = ApiClient.convertToType(data['Department'], 'String');
            }
            if (data.hasOwnProperty('ProjectStatus')) {
                obj['ProjectStatus'] = ApiClient.convertToType(data['ProjectStatus'], 'String');
            }
            if (data.hasOwnProperty('ProjectClient') && !!data.ProjectClient) {
                // obj['ProjectClient'] =  data['ProjectClient'].map(k=>(
                //     {
                //     ...k,
                //     ...k.Client,
                //     Name:getNameStr(k['Client'])}));
                obj['ProjectClient'] = ApiClient.convertToType(data['ProjectClient'], 'Object');
                obj['ProjectClient']['Name'] = getNameStr(data['ProjectClient']);

            }
            if (data.hasOwnProperty('ProjectUsers')) {
                obj['ProjectUsers'] = ApiClient.convertToType(data['ProjectUsers'], [ProjectUser]);
            }

            // if (data.hasOwnProperty('Role')) {
            //     obj['Role'] = ApiClient.convertToType(data['Role'], 'String');
            // }

            if (data.hasOwnProperty('Id')) {
                obj['Id'] = ApiClient.convertToType(data['Id'], 'String');
            }



            if (data.hasOwnProperty('ProjectAddresses')) {
                obj['ProjectAddresses'] = ApiClient.convertToType(data['ProjectAddresses'], 'Array');
            }
            if (data.hasOwnProperty('PrimaryContact')) {
                obj['PrimaryContact'] = ApiClient.convertToType(data['PrimaryContact'], 'String');
            }
            if (data.hasOwnProperty('ClientProjectManager')) {
                obj['ClientProjectManager'] = ApiClient.convertToType(data['ClientProjectManager'], 'String');
            }
            if (data.hasOwnProperty('ClientManagerEmail')) {
                obj['ClientManagerEmail'] = ApiClient.convertToType(data['ClientManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('ClientManagerPhoneNumber')) {
                obj['ClientManagerPhoneNumber'] = ApiClient.convertToType(data['ClientManagerPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('ContractorProjectManager')) {
                obj['ContractorProjectManager'] = ApiClient.convertToType(data['ContractorProjectManager'], 'String');
            }
            if (data.hasOwnProperty('ContractorManagerEmail')) {
                obj['ContractorManagerEmail'] = ApiClient.convertToType(data['ContractorManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('ContractorManagerPhoneNumber')) {
                obj['ContractorManagerPhoneNumber'] = ApiClient.convertToType(data['ContractorManagerPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty('SubContractorProjectManager')) {
                obj['SubContractorProjectManager'] = ApiClient.convertToType(data['SubContractorProjectManager'], 'String');
            }

            if (data.hasOwnProperty('SubContractorManagerEmail')) {
                obj['SubContractorManagerEmail'] = ApiClient.convertToType(data['SubContractorManagerEmail'], 'String');
            }
            if (data.hasOwnProperty('SubContractorManagerPhoneNumber')) {
                obj['SubContractorManagerPhoneNumber'] = ApiClient.convertToType(data['SubContractorManagerPhoneNumber'], 'String');
            }
            if (data.hasOwnProperty("ProjectOrder")) {
                obj["ProjectOrder"] = ApiClient.convertToType(data["ProjectOrder"], "String");
            }




            if (data.hasOwnProperty('DOB40HSSM')) {
                obj['DOB40HSSM'] = ApiClient.convertToType(data['DOB40HSSM'], 'Boolean');
            }


            if (data.hasOwnProperty('OSHA30HR')) {
                obj['OSHA30HR'] = ApiClient.convertToType(data['OSHA30HR'], 'Boolean');
            }


            if (data.hasOwnProperty('ASBESTOSINSPECTOR')) {
                obj['ASBESTOSINSPECTOR'] = ApiClient.convertToType(data['ASBESTOSINSPECTOR'], 'Boolean');
            }


            if (data.hasOwnProperty('AMPPC3C5')) {
                obj['AMPPC3C5'] = ApiClient.convertToType(data['AMPPC3C5'], 'Boolean');
            }


            if (data.hasOwnProperty('ASSPMEMBERSHIP')) {
                obj['ASSPMEMBERSHIP'] = ApiClient.convertToType(data['ASSPMEMBERSHIP'], 'Boolean');
            }


            if (data.hasOwnProperty('NYCTTRACKSAFETYTRAINED')) {
                obj['NYCTTRACKSAFETYTRAINED'] = ApiClient.convertToType(data['NYCTTRACKSAFETYTRAINED'], 'Boolean');
            }


            if (data.hasOwnProperty('LIRRTRACKCARD')) {
                obj['LIRRTRACKCARD'] = ApiClient.convertToType(data['LIRRTRACKCARD'], 'Boolean');
            }


            if (data.hasOwnProperty('MNRCARD')) {
                obj['MNRCARD'] = ApiClient.convertToType(data['MNRCARD'], 'Boolean');
            }


            if (data.hasOwnProperty('AMTRACKCARD')) {
                obj['AMTRACKCARD'] = ApiClient.convertToType(data['AMTRACKCARD'], 'Boolean');
            }


            if (data.hasOwnProperty('SIRCARD')) {
                obj['SIRCARD'] = ApiClient.convertToType(data['SIRCARD'], 'Boolean');
            }


            if (data.hasOwnProperty('CSXTRAINING')) {
                obj['CSXTRAINING'] = ApiClient.convertToType(data['CSXTRAINING'], 'Boolean');
            }


            if (data.hasOwnProperty('CHST')) {
                obj['CHST'] = ApiClient.convertToType(data['CHST'], 'Boolean');
            }






            if (data.hasOwnProperty('NINTYDROWNYCT')) {
                obj['NINTYDROWNYCT'] = ApiClient.convertToType(data['NINTYDROWNYCT'], 'Boolean');
            }


            if (data.hasOwnProperty('NINTYDROWMNR')) {
                obj['NINTYDROWMNR'] = ApiClient.convertToType(data['NINTYDROWMNR'], 'Boolean');
            }


            if (data.hasOwnProperty('NINTYDROWLIRR')) {
                obj['NINTYDROWLIRR'] = ApiClient.convertToType(data['NINTYDROWLIRR'], 'Boolean');
            }


            if (data.hasOwnProperty('NINTYDROWAMTRACK')) {
                obj['NINTYDROWAMTRACK'] = ApiClient.convertToType(data['NINTYDROWAMTRACK'], 'Boolean');
            }


            if (data.hasOwnProperty('NINTYDROWCSX')) {
                obj['NINTYDROWCSX'] = ApiClient.convertToType(data['NINTYDROWCSX'], 'Boolean');
            }


            if (data.hasOwnProperty('TWOYSAFETYCOORDINATOR')) {
                obj['TWOYSAFETYCOORDINATOR'] = ApiClient.convertToType(data['TWOYSAFETYCOORDINATOR'], 'Boolean');
            }


            if (data.hasOwnProperty('FIVEYSAFETYCOORDINATOR')) {
                obj['FIVEYSAFETYCOORDINATOR'] = ApiClient.convertToType(data['FIVEYSAFETYCOORDINATOR'], 'Boolean');
            }

        }
        return obj;
    }


}

/**
 * project first name
 * @member {String} ProjectName
 */
Project.prototype['ProjectName'] = undefined;

/**
 * project first name
 * @member {String} Location
 */
Project.prototype['Location'] = undefined;
/**
 * project first name
 * @member {String} Client
 */
Project.prototype['Client'] = undefined;
/**
 * project first name
 * @member {String} Contractor
 */
Project.prototype['Contractor'] = undefined;
/**
 * client first name
 * @member {String} ProjectType
 */
Project.prototype['ProjectType'] = undefined;
/**
* @member {String} SubContractor
*/
Project.prototype['SubContractor'] = undefined;

/**
* @member {Boolean} PaymentReceived
*/
Project.prototype['PaymentReceived'] = undefined;

/**
* @member {String} PaymentDate
*/
Project.prototype['PaymentDate'] = undefined;

/**
* @member {String} ProjectNo
*/
Project.prototype['ProjectNo'] = undefined;
/**
* @member {String} Landmark
*/
Project.prototype['Landmark'] = undefined;

/**
* rolw
* @member {module:model/Project.Role} Role
*/
Project.prototype['Role'] = undefined;

/**
 * @member {String} Id
 */
Project.prototype['Id'] = undefined;
/**
 * @member {Object} ProjectClient
 */
Project.prototype['ProjectClient'] = undefined;


Project.prototype['PrimaryContact'] = undefined;
Project.prototype['ClientProjectManager'] = undefined;
Project.prototype['ClientManagerEmail'] = undefined;
Project.prototype['ClientManagerPhoneNumber'] = undefined;
Project.prototype['ContractorProjectManager'] = undefined;
Project.prototype['ContractorManagerEmail'] = undefined;
Project.prototype['ContractorManagerPhoneNumber'] = undefined;
Project.prototype['SubContractorProjectManager'] = undefined;
Project.prototype['SubContractorManagerEmail'] = undefined;
Project.prototype['SubContractorManagerPhoneNumber'] = undefined;
Project.prototype['ProjectAddresses'] = undefined;


export default Project;

