import { Button } from '@mui/material';
import { getEmailStr, getNameStr } from 'utils/strUtils';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ columns, data }) => {

    const handleExport = () => {
        const wb = XLSX.utils.book_new();
        const wsData = [];

        // Create the header row
        const header = columns.map((column) => column.label);
        wsData.push(header);

        // Create the data rows
        data.forEach((row) => {
            const rowData = columns.map((column) => {
                switch (column.id) {
                    case 'SafetyPersonal':
                    case 'WeekDayTimeSlots':
                    case 'WeekEndTimeSlots':
                    case 'Availability':
                    case 'Mon':
                    case 'Tue':
                    case 'Wed':
                    case 'Thu':
                    case 'Fri':
                    case 'Sat':
                    case 'Sun':
                        return row[column.id]?.map(item => item.Label || item).join(', ');
                    case 'Recommended':
                        return row[column.id] === 'Yes' ? 'Yes' : 'No';   
                    case 'IsActive':
                        return row[column.id] ? 'Yes' : 'No';
                    case 'Department':
                        return row[column.id]?.toUpperCase() 
                    case 'Departments':
                        return row[column.id]?.map(item => item.Department?.toUpperCase() || item).join(', ');
                    case 'ProjectUsers':
                    case 'ClientUsers':
                        return row[column.id]?.map(k => `${getNameStr(k.User)} <${getEmailStr(k.User)}>`).join(', ');
                    default:
                        if (column.type === 'creed') {
                            // Handling Expiration, LicenseNo, and Link
                            const expiration = row[column.id]?.Expiration ? `Expiration: ${row[column.id]?.Expiration}` : '';
                            const licenseNo = row[column.id]?.LicenseNo ? `License No: ${row[column.id]?.LicenseNo}` : '';
                            const link = row[column.id]?.Link ? `Link: ${row[column.id]?.Link}` : '';

                            // Combining into a single cell value
                            return [expiration, licenseNo, link].filter(Boolean).join(' | ');
                        } else if (column.creedFieldType === 'YesNo') {
                            return row[column.id]?.HasCertification ? 'Yes' : 'No';
                        } else {
                            return row[column.id];
                        }
                }
            });
            wsData.push(rowData);
        });

        // Create worksheet
        const ws = XLSX.utils.aoa_to_sheet(wsData);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Data');

        // Export to Excel file
        XLSX.writeFile(wb, 'ExportedTableData.xlsx');
    };

    return (
        <Button onClick={handleExport} variant="contained" color="primary" size='small'>
            Export
        </Button>
    );
};

export default ExportToExcel;
