import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete, Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import FlexBox from "components/common/FlexBox";


import PrimaryButton from "components/common/PrimaryButton";
import RosterView from "components/roster/RosterView";
import moment from "moment";
import PropTypes from "prop-types";
import { prop } from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  ".MuiDialog-paper": {
    // backgroundColor: 'yellow',
    margin: "5px",
    width: "100%",
    borderRadius: "10px",
    maxWidth: '100%'
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: "100vh",

    backgroundColor: theme.palette.background.paper,
  },
  title: {
    display: "flex",
    justifyItems: "center",
    justifyContent: "center",
    fontSize: '16px'
  },
  top: {

    backgroundColor: theme.palette.background.paper,
  },
  container: {
    // position: "relative",

    padding: theme.spacing(2),
  },
  bottomBox: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1, fontSize: "16px" }} {...other}>
      <strong> {children}</strong>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{

            position: "absolute!important",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddUserToCreedReqDialogue({ handleClose, userCreedsList, open, data, onAddUserToCreedSave, department, creedRequest }) {
  const classes = useStyles();
  const [selectedRows, setSelectedRows] = useState([]);
  const { creedRecommendation } = useSelector(prop('creedRequest'));
  const { creeds } = useSelector(prop('creed'));
  const [selectedCreeds, setSelectedCreeds] = useState();
  const [disabledIds, setdisabledIds] = useState();
  const { users, LoginUserRole, seletedRole } = useSelector(prop("user"));
  const dispatch = useDispatch();

  const client = data || {};
  // const { userName = "", role = "", MobileNo = "", isNew = false,id=null } = user;

  useEffect(() => {
    if (userCreedsList) {
      setSelectedRows(userCreedsList.map(k => k.User.Id?.toString()))
      setdisabledIds(userCreedsList.filter(k => k.Status == 'Reassigned' || k.Status == 'UnAvailable').map(k => k.User.Id?.toString()))
    }
  }, [userCreedsList])

  const onAdd = () => {
    onAddUserToCreedSave(selectedRows)
  };

  const disabled = false;

  const handleCheckboxChange = (rowId) => {
    const selectedRowIds = [...selectedRows];
    if (selectedRowIds.includes(rowId)) {
      // Remove rowId if already selected
      setSelectedRows(selectedRowIds.filter(id => id !== rowId));
    } else {
      // Add rowId if not selected
      setSelectedRows([...selectedRowIds, rowId]);
    }
  };
  const validateAndSelectUser = () => {
    if (selectedCreeds?.length > 0) {
      let setectedCreedIds = selectedCreeds.map(k => k.Id);
      let tempUsers = users        
        .filter(k => k.Departments?.some(d => ['mta', 'dob'].includes(d.Department?.toLowerCase())))
        .filter(k => department ? k.Departments?.some(d => d.Department?.toLowerCase() === department.toLowerCase()) : true);

      let tempSelectedUsers = [];
      tempUsers.forEach(user => {
        let creed = user.UserCreeds.find(k => setectedCreedIds.includes(k.Creed.Id + ""));
        if (creed) {
          let todat = moment(new Date());
          let exp = moment(creed.ExpirationDate)
          if (user.EmploymentType != 'consultant' && (todat.isSameOrBefore(exp) || creed.NoExpiration || creed.HasCertification)) {
            tempSelectedUsers.push(user.Id);
          }
        }
      })
      disabledIds.forEach(id => {
        if (!tempSelectedUsers.includes(id)) {
          tempSelectedUsers.push(id);
        }
      })
      setSelectedRows(tempSelectedUsers);
    }
  }
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}

      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={classes.title}
        >
          Add User
        </BootstrapDialogTitle>
        <DialogContent>
          <>
            <FlexBox alignItems="center" >
              <Autocomplete
                id="user-type-select"
                options={creeds?.filter(k => k.ShowInRoaster && k.Department?.toLowerCase() === department?.toLowerCase())}
                multiple={true}
                sx={{ minWidth: '250px', maxWidth: '250px' }}
                ChipProps={{ size: 'small' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Types"
                    variant="standard"
                    margin="normal"
                    fullWidth
                  />
                )}
                getOptionLabel={(option) => option.Name}
                value={selectedCreeds}
                onChange={(_event, newValue) => {
                  setSelectedCreeds(newValue)
                }}
              />
              <Button variant="contained" disabled={selectedCreeds?.length === 0} onClick={validateAndSelectUser} size="small">Select All </Button>
            </FlexBox>

          <RosterView
              tableHeight={'calc(100vh - 366px)'}
            showRecommended={true}
              isAddUserToRosterFlow={true}
              projectUserRatings={true}
            selectedDepartmentValue={department}
            handleCheckboxChange={handleCheckboxChange}
            selectedRows={selectedRows}
              disabledIds={disabledIds}
            setSelectedRows={setSelectedRows}
            alowEdit={false}
            showCheckColumn={true}
            allowDepartmentChange={false}
            creedRecommendation={creedRecommendation}
              creedRequest={creedRequest}
          />

          </>

        </DialogContent>
        <DialogActions sx={{ p: 0 }}>
          <PrimaryButton autoFocus onClick={onAdd} size="small">
            Save
          </PrimaryButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
