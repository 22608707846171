import { ArrowDownward, ArrowUpward } from '@mui/icons-material'; // Import MUI icons for sorting
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { Avatar, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { prop } from 'ramda';
import { default as React, useState } from 'react';
import { useSelector } from 'react-redux';
import { abbr, formatDateTime } from 'utils/strUtils';
import { STATUS_MAP } from './Card-Item';
export default function CreedRequestTable({ creedRequests, onEditClick, onDeleteClick, onRateReviewClick, onCancelClick, onAuditLogClick }) {
    const { LoginUserRole } = useSelector(prop("user"));
    const theme = useTheme();

    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

    // Function to handle sorting
    const handleSort = (columnName) => {
        let direction = 'ascending';
        if (sortConfig.key === columnName && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnName, direction: direction });
    };

    // Function to sort the list based on sortConfig
    const sortedList = [...creedRequests].sort((a, b) => {
        if (sortConfig.key !== null) {
            const key = sortConfig.key;
            const aValue = a[key];
            const bValue = b[key];

            if (sortConfig.direction === 'ascending') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
            }
        }
        return 0;
    });

    function getColorBackground(pstatus) {
        if (pstatus === "Completed" || pstatus === "Approved") {
            return theme.palette.common.green
        } else if (pstatus === "Cancelled") {
            return theme.palette.common.red
        } else if (pstatus === "Raised") {
            return theme.palette.secondary.gray
        } else if (pstatus === 'Reassigned') {
            return '#ddebf7'
        } else {
            return theme.palette.warning.main
        }
    }

    function getColor(pstatus) {
        if (pstatus === "Completed" || pstatus === "Approved") {
            return theme.palette.common.white
        } else if (pstatus === "Cancelled") {
            return theme.palette.common.white
        } else if (pstatus === "Raised" || pstatus === "Reassigned") {
            return theme.palette.common.black
        } else {
            return theme.palette.warning.black
        }
    }

    return (
        <TableContainer component={Paper} style={{ margin: '8px 0px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="creed requests table">
                <TableHead>
                    <TableRow >
                        <TableCell onClick={() => handleSort('Avatar')} style={{ fontWeight: '600', }}>
                            Avatar
                            {/* {sortConfig.key === 'Avatar' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )} */}
                        </TableCell>
                        <TableCell onClick={() => handleSort('CreedName')}>
                            Job Position
                            {sortConfig.key === 'CreedName' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('ProjectName')}>
                            Project Name
                            {sortConfig.key === 'ProjectName' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('FirstName')}>
                            Requested By
                            {sortConfig.key === 'FirstName' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('Location')}>
                            Location
                            {sortConfig.key === 'Location' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('ContactName')}>
                            Contact Name
                            {sortConfig.key === 'ContactName' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('ContactPhoneNumber')}>
                            Contact Phone
                            {sortConfig.key === 'ContactPhoneNumber' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('Comment')}>
                            Comment
                            {sortConfig.key === 'Comment' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('Status')}>
                            Status
                            {sortConfig.key === 'Status' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('StartDate')}>
                            Start Date
                            {sortConfig.key === 'StartDate' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell onClick={() => handleSort('EndDate')}>
                            End Date
                            {sortConfig.key === 'EndDate' && (
                                sortConfig.direction === 'ascending' ? <ArrowUpward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} /> : <ArrowDownward sx={{ fontSize: '1rem', transform: 'translate(2px, 3px)' }} />
                            )}
                        </TableCell>
                        <TableCell>Accepted By</TableCell>

                        <TableCell>Filled In For</TableCell>
                        <TableCell sx={{ minWidth: '180px' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedList.map((creedRequest) => (
                        <TableRow key={creedRequest.id}>
                            <TableCell>
                                <Avatar sx={{ bgcolor: getColorBackground(creedRequest.Status) }} aria-label="recipe">
                                    {abbr(STATUS_MAP[creedRequest.Status] || "")}
                                </Avatar>
                            </TableCell>
                            <TableCell>{creedRequest.Creed.CreedName}</TableCell>
                            <TableCell>{creedRequest.Project.ProjectName}</TableCell>
                            <TableCell>{creedRequest.RequestBy?.FirstName}</TableCell>
                            <TableCell>{creedRequest.Location}</TableCell>
                            <TableCell>{creedRequest.ContactName}</TableCell>
                            <TableCell>{creedRequest.ContactPhoneNumber}</TableCell>
                            <TableCell>{creedRequest.Comment}</TableCell>
                            <TableCell>
                                <Chip label={STATUS_MAP[creedRequest.Status]} size="small" sx={{ background: getColorBackground(creedRequest.Status), color: getColor(creedRequest.Status) }} />
                            </TableCell>
                            <TableCell>{formatDateTime(creedRequest.StartDate)}</TableCell>
                            <TableCell>{formatDateTime(creedRequest.EndDate)}</TableCell>
                            <TableCell>{creedRequest.UserCreedsNotify?.filter(k => k.Status === 'Approved' || k.Status === 'Available')?.map(k => k.User.FirstName).join(', ')}</TableCell>

                            <TableCell>{creedRequest.FillInFor?.Name}</TableCell>
                            <TableCell>
                                {(LoginUserRole === "admin" || LoginUserRole === "clientuser") && (
                                    <>
                                        <IconButton aria-label="cancel" onClick={() => onCancelClick(creedRequest)}>
                                            <Tooltip title="Cancel">
                                                <CancelIcon />
                                            </Tooltip>
                                        </IconButton>
                                        {LoginUserRole === "admin" && (
                                            <IconButton aria-label="delete" onClick={() => onDeleteClick(creedRequest)}>
                                                <Tooltip title="Delete">
                                                    <DeleteIcon />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                        {LoginUserRole === "admin" && (
                                            <IconButton aria-label="audit" onClick={() => onAuditLogClick(creedRequest)}>
                                                <Tooltip title="Activity Log">
                                                    <InfoIcon />
                                                </Tooltip>
                                            </IconButton>
                                        )}
                                        <IconButton aria-label="rate review" onClick={() => onRateReviewClick(creedRequest)}>
                                            <Tooltip title="Rate Review">
                                                <RateReviewIcon />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton aria-label="edit" onClick={() => onEditClick(creedRequest)}>
                                            <Tooltip title="Edit">
                                                <ModeEditOutlineIcon />
                                            </Tooltip>
                                        </IconButton>
                                    </>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
