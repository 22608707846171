import { Button, DialogActions, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import makeStyles from '@mui/styles/makeStyles';
import { SlideUp } from "components/common/transitions";
import React from 'react';
import { getNameStr } from "utils/strUtils";
const useStyles = makeStyles({
    topScrollPaper: {
        alignItems: "flex-end",
        width: '100%'
    },
    topPaperScrollBody: {
        margin: '0',
        width: '100%'
    },

});
const auditTypes = {
    NewCoverageReceived: 'New coverage was received from',
    CanceledCoverage: 'Coverage was canceled',
    AssignedToUser: 'Task was assigned',
    AcceptedByUser: 'Task was accepted',
    RejectedByUser: 'Task was rejected',
    NewTaskAssigned: 'A new task was assigned',
    TaskUpdated: 'Task was updated',
    ReAssigned: 'Task was reassigned',
    ClientFeedBack: 'Client provided feedback from',
}

export default function AuditLogDialog({ open, onClose, creedRequest = {} }) {
    const classes = useStyles();
    const { AuditLogs } = creedRequest;
    const getSubText = () => {
        let str = '';
        if (AuditLogs != null && AuditLogs.length > 0) {
            const sortedAuditLogs = AuditLogs.sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate));

            str = sortedAuditLogs.map(audit => {
                const action = auditTypes[audit.AuditType] || 'Unknown action';
                const date = new Date(audit.CreateDate).toLocaleString();
                const actionedBy = getNameStr(audit.CreatedBy);
                const toUser = audit.ToUser ? ` to ${getNameStr(audit.ToUser)}` : '';  // Only include 'toUser' when it exists
                const finalAction = action.endsWith('from') && !toUser ? action.replace('from', 'by') : action;

                return `On ${date}, ${finalAction}${toUser} by ${actionedBy}.`;
            }).join('\n');
        }
        return str;
    }
    return (
        <Dialog open={open}
            onClose={onClose}
            TransitionComponent={SlideUp}
            classes={{
                scrollPaper: classes.topScrollPaper,
                paperScrollPaper: classes.topPaperScrollBody
            }}
        >
            <DialogContent>
                <Typography gutterBottom > {"Activity Logs"}</Typography>
                <Typography variant="subtitle2" sx={{ whiteSpace: 'pre-line' }}>{getSubText()}</Typography>
            </DialogContent>
            <DialogActions>
                {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    data-testid='btn-dialog-back'>
                    Back
                </Button> */}
                <Button variant="contained"
                    color="secondary"
                    onClick={onClose}
                    data-testid='btn-dialog-remove'
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}