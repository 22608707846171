
import defaultValue from "assets/data/default-value";

var moment = require('moment-timezone');

export const ISO_8601_DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT = 'MMM D';
export const UNIX_DATE_FORMAT = 'yyyy-MM-DD';
export const FULL_DATE_FORMAT = 'MMM D YYYY';
export const TIME_FORMAT = 'h:mm A';
export const DATE_TIME_FORMAT = 'MMM D, h:mm A';

export function abbr(name = '', maxLength = 2) {
  return name.split(' ').map(s => s[0]).join('').substr(0, maxLength).toUpperCase();
}

const USD_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const USD_NO_CENTS_FORMAT = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

function validNumber(n) {
  return !isNaN(n);
}

export function formatUSD(amount) {
  return validNumber(amount) ? USD_FORMAT.format(amount) : '';
}

export function formatUSDNoCents(amount) {
  return validNumber(amount) ? USD_NO_CENTS_FORMAT.format(amount) : '';
}

export function formatCents(amount) {
  return formatUSD(amount).split('.')[1] || '';
}


export function formatDate(date) {
  return moment(date).format(DATE_FORMAT)
}

export function formatUnixDate(date) {
  return moment(date).format(UNIX_DATE_FORMAT)
}


export function formatFullDate(date) {
  return moment(date).format(FULL_DATE_FORMAT)
}

export function formatDateTime(date) {
  return moment(date).format(DATE_TIME_FORMAT);
}

export function formatTime(date) {
  return moment(date).format(TIME_FORMAT);
}

export function formatISODate(date) {
  return moment(date).format(ISO_8601_DATE_FORMAT);
}

export function headingZeros(number, length = 2) {
  return ('0' + number).slice(-length);
}
export function formattedTime(originalTime) {
  return moment(originalTime, "HH:mm:ss").format("HH:mm");
};

export function getNameStr(obj) {

  if (!obj) {
    return null;
  }
  const name = [];
  if (obj.Salutation) {
    name.push(obj.Salutation);
  }
  if (obj.FirstName) {
    name.push(obj.FirstName);
  }
  if (obj.LastName) {
    name.push(obj.LastName);
  }


  if (!obj.FirstName && obj.Name) {
    name.push(obj.Name);
  }
  if (name.length > 0) {
    return name.join(' ');
  }
  return obj.Email;

}
export function getEmailStr(obj) {

  if (!obj) {
    return null;
  }

  return obj.Email || '';

}
export function parseDateWithTZ(str) {
  const date = moment.tz(str, "GMT");
  return date.clone().tz("GMT").format(defaultValue.saveDateTimeFormat);
}